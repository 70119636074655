import React, { useEffect, useState } from 'react';

import { useField } from 'formik';
import { toNumber } from 'lodash';

import ClubMembersList from '@components/ClubMembersList';
import SearchMembersForm from '@components/Formik/SearchMembersForm';
import { SearchFormValues } from '@components/Formik/SearchMembersForm/types';
import Select, { SelectOptions } from '@components/Formik/Select';
import { WizardPage } from '@components/Formik/Wizard';
import IdentifyOfficerResult from '@components/IdentifyOfficerResult';
import Loading from '@components/Loading';
import { ResultsListItem } from '@components/ResultsList';
import TableWithTabs from '@components/TableWithTabs';
import { TabInfo } from '@components/TableWithTabs/types';
import Title from '@components/Title';

import { Individual } from '@domain/clubs/types';
import { DelegateRoleValues } from '@domain/delegation';

import { useErrorHandling } from '@use-cases/notifications';

import { useSearchClubOfficerLazyQuery } from '@repositories/clubs';
import { useFetchRegionalGroupDistricts } from '@repositories/delegation/hooks/useFetchRegionalGroupDistricts';
import { useFetchDistrictMembers } from '@repositories/districts/queries';

import { getClubRoleTValue } from '@utils/getClubRoleTValue';

import { useTranslation } from '@external/react-i18next';

import { MembershipStatusFilter, MembershipType } from '@typings/graphql';

type SearchFields = {
  name?: string;
  email?: string;
};

const TAB_CLUB_MEMBERS = 'Club Members';
const TAB_DISTRICT_MEMBERS = 'District Members';

const SelectOfficer: WizardPage<DelegateRoleValues> = () => {
  const { t } = useTranslation();
  const [orgId] = useField<string>('orgId');
  const [roleId] = useField<string>('roleId');
  const [roleName] = useField<string>('roleName');
  const [orgName] = useField<string>('orgName');
  const [orgType] = useField<string>('orgType');
  const [delegatorId] = useField<string>('delegatorId');
  const [isClubRole] = useField<boolean>('isClubRole');
  const [isDistrictRole] = useField<boolean>('isDistrictRole');
  const [isRegionalGroupRole] = useField<boolean>('isRegionalGroupRole');
  const [, , helpersDelegatee] = useField<Individual>('delegatee');
  const [regionDistrictSelected, , helpersRegionDistrictSelected] = useField<
    string
  >('regionDistrictSelected');
  const [searchFields, setSearchFields] = useState<SearchFields>({});
  const [currentPage, setCurrentPage] = useState(1);
  const [
    selectedRegionalRoleDistrict,
    setSelectedRegionalRoleDistrict,
  ] = useState<string>('');

  const getRoleTabDetails = (
    isClubRole: boolean,
    isDistrictRole: boolean,
    isRegionalGroupRole: boolean
  ) => {
    if (isClubRole) {
      return {
        activeTab: TAB_CLUB_MEMBERS,
        tableTitle: t('delegate-role.club-members', 'Club Members'),
        searchTitle: t(
          'delegate-role.search-members-title',
          'Search club members'
        ),
      };
    }
    if (isDistrictRole || isRegionalGroupRole) {
      return {
        activeTab: TAB_DISTRICT_MEMBERS,
        tableTitle: t('delegate-role.district-members', 'District Members'),
        searchTitle: t(
          'delegate-role.search-district-members-title',
          'Search district members'
        ),
      };
    }
    return {
      activeTab: '',
      tableTitle: '',
      searchTitle: '',
    };
  };

  const [activeTab, setActiveTab] = useState<TabInfo>({
    name: getRoleTabDetails(
      isClubRole.value,
      isDistrictRole.value,
      isRegionalGroupRole.value
    ).activeTab,
    scrollTo: 0,
  });
  const PAGE_SIZE = 10;

  const paginationHandler = (
    event: React.SyntheticEvent,
    pageNumber: number
  ) => {
    event.preventDefault();
    setCurrentPage(pageNumber);
  };

  const handleSearch = ({ name, email }: SearchFormValues) => {
    setSearchFields({ name, email });
    setCurrentPage(1);
  };

  const handleSelect = (
    id: string,
    name: string,
    email: string,
    riIndividualId: string | null,
    membershipAdmissionDate?: string | null,
    membershipTerminationDate?: string | null,
    preferredLanguage?: string | null
  ) => {
    const params = {
      id,
      name,
      email,
      membershipAdmissionDate,
      membershipTerminationDate,
      roleId,
      riIndividualId,
      preferredLanguage,
    };

    helpersDelegatee.setValue(params);
    window.scrollTo(0, 0);
    if (isRegionalGroupRole) {
      helpersRegionDistrictSelected.setValue(selectedRegionalRoleDistrict);
    }
  };

  const membershipType =
    orgType.value === 'Rotary Club'
      ? MembershipStatusFilter.Active
      : MembershipStatusFilter.Rotaractor;

  const [
    searchClubOfficers,
    { data, loading, error },
  ] = useSearchClubOfficerLazyQuery();

  const [
    fetchDistrictMembers,
    {
      data: districtMembersData,
      loading: districtMembersLoading,
      error: districtMembersError,
    },
  ] = useFetchDistrictMembers();

  const [
    fetchRegionalGroupDistricts,
    {
      data: dataRegionalGroupDistricts,
      loading: regionalGroupDistrictsLoading,
      error: errorRegionalGroupDistricts,
    },
  ] = useFetchRegionalGroupDistricts();

  useEffect(() => {
    if (
      selectedRegionalRoleDistrict === '' &&
      regionDistrictSelected.value !== '' &&
      isRegionalGroupRole
    ) {
      setSelectedRegionalRoleDistrict(regionDistrictSelected.value);
    }
  }, [
    selectedRegionalRoleDistrict,
    regionDistrictSelected.value,
    isRegionalGroupRole,
  ]);

  useEffect(() => {
    if (isRegionalGroupRole.value && orgId.value) {
      fetchRegionalGroupDistricts({
        variables: {
          regionalGroupId: orgId.value,
        },
      });
    }
  }, [isRegionalGroupRole.value, orgId.value]);

  useEffect(() => {
    if (orgId.value && isClubRole.value) {
      searchClubOfficers({
        variables: {
          name: searchFields.name,
          email: searchFields.email,
          clubId: orgId.value,
          pageSize: PAGE_SIZE,
          page: currentPage,
          membershipStatus: membershipType,
          riClubId: orgId.value,
        },
      });
    }
    if (orgId.value && isDistrictRole.value) {
      fetchDistrictMembers({
        variables: {
          district: orgId.value,
          pageSize: PAGE_SIZE,
          page: currentPage,
          name: searchFields.name,
          email: searchFields.email,
          memberTypes: [MembershipType.Member, MembershipType.Rotaractor],
          allowRotaractors: true,
        },
      });
    }
    if (
      orgId.value &&
      isRegionalGroupRole.value &&
      selectedRegionalRoleDistrict &&
      selectedRegionalRoleDistrict !== ''
    ) {
      fetchDistrictMembers({
        variables: {
          district: `${selectedRegionalRoleDistrict}`,
          pageSize: PAGE_SIZE,
          page: currentPage,
          name: searchFields.name,
          email: searchFields.email,
          memberTypes: [MembershipType.Member, MembershipType.Rotaractor],
          allowRotaractors: true,
        },
      });
    }
  }, [
    orgId.value,
    isClubRole.value,
    isDistrictRole.value,
    isRegionalGroupRole.value,
    currentPage,
    searchFields.name,
    searchFields.email,
    selectedRegionalRoleDistrict,
  ]);

  const isError = Boolean(
    error || districtMembersError || errorRegionalGroupDistricts
  );

  useErrorHandling(error?.message, isError, 'search-officer.error');

  if (
    loading ||
    regionalGroupDistrictsLoading ||
    (districtMembersLoading && !selectedRegionalRoleDistrict)
  ) {
    return <Loading />;
  }

  const { totalCount } =
    data?.clubById.members || districtMembersData?.members || {};

  const getMembers = (
    isClubRole: boolean,
    isDistrictRole: boolean,
    isRegionalGroupRole: boolean
  ) => {
    if (isClubRole) {
      return data?.clubById.members.results;
    }
    if (isDistrictRole || isRegionalGroupRole) {
      return districtMembersData?.members.results.map(result => ({
        ...result,
        leadershipsFromThisClub: result.thisDistrictLeadership,
        membershipsFromThisClub: result.activeMemberships,
      }));
    }
    return [];
  };

  const membersTab = {
    name: getRoleTabDetails(
      isClubRole.value,
      isDistrictRole.value,
      isRegionalGroupRole.value
    ).activeTab,
    title: `${
      getRoleTabDetails(
        isClubRole.value,
        isDistrictRole.value,
        isRegionalGroupRole.value
      ).tableTitle
    } (${totalCount})`,

    component: (
      <ClubMembersList
        totalCount={toNumber(totalCount)}
        tableTitle={
          <h2 className="font-bold uppercase tracking-wide text-sm mb-0">
            {
              getRoleTabDetails(
                isClubRole.value,
                isDistrictRole.value,
                isRegionalGroupRole.value
              ).tableTitle
            }{' '}
            ({totalCount})
          </h2>
        }
        headerClassName="-my-2 px-5"
        pageSize={PAGE_SIZE}
        currentPage={currentPage}
        pageHandler={paginationHandler}
      >
        {getMembers(
          isClubRole.value,
          isDistrictRole.value,
          isRegionalGroupRole.value
        )?.map(member => (
          <ResultsListItem key={member.id} spacingY={0}>
            <IdentifyOfficerResult
              name={member.name}
              individualIdDisabled={delegatorId.value}
              isReplaceOperation
              individualId={member.id}
              photoUri={member.photoUri}
              role={
                (member.leadershipsFromThisClub[0]?.role &&
                  getClubRoleTValue(
                    t,
                    member.leadershipsFromThisClub[0]?.role
                  )) ||
                ''
              }
              roleId={roleId.value}
              type={member.membershipsFromThisClub[0]?.type || ''}
              admissionDate={
                member.membershipsFromThisClub[0]?.admissionDate || ''
              }
              email={member.email || ''}
              phoneNumber={member.phoneNumber || ''}
              selectMember={value => {
                handleSelect(
                  value,
                  member.name,
                  member.email || '',
                  member.riIndividualId,
                  member.membershipsFromThisClub[0]?.admissionDate || '',
                  member.membershipsFromThisClub[0]?.terminationDate || '',
                  member.preferredLanguage?.id || ''
                );
              }}
              expanded={totalCount === 1}
              isDisabled={!member.onlineId}
            />
          </ResultsListItem>
        ))}
      </ClubMembersList>
    ),
  };

  const tabs = [membersTab];

  const onChangeRegionalRoleDistrict = (option: string) => {
    setSelectedRegionalRoleDistrict(option);
  };

  return (
    <>
      <Title className="h2 desktop:mb-4 desktop:mt-7 mobile:mt-4 mobile:mb-1 mobile:font-normal desktop:font-light">
        {t('delegate-role.title', 'Delegate role')}
      </Title>

      <Title className="h3 desktop:mb-5 mobile:mb-6">
        {t(
          'delegate-role.role-description',
          '{{roleName}} in {{orgType}} {{orgName}}',
          {
            roleName: roleName.value,
            orgType: orgType.value,
            orgName: orgName.value,
          }
        )}
      </Title>

      {isRegionalGroupRole.value && (
        <div
          data-testid="regionalRoleDistricts"
          className="w-full tablet:w-72 desktop:w-72 desktop:mb-5 mobile:mb-6"
        >
          <Select
            name="regionalRoleDistricts"
            options={
              dataRegionalGroupDistricts?.regionalGroupDistricts.map(
                ({ DistrictId }) => {
                  return {
                    value: DistrictId,
                    label: DistrictId,
                  };
                }
              ) as SelectOptions
            }
            label={t('delegate-role.regional-districts', 'District*')}
            onChange={onChangeRegionalRoleDistrict}
            placeholder={t(
              'delegate-role.select-district',
              'Select district...'
            )}
          />
        </div>
      )}

      {selectedRegionalRoleDistrict !== '' && districtMembersLoading && (
        <Loading />
      )}

      {(isClubRole.value ||
        isDistrictRole.value ||
        (selectedRegionalRoleDistrict !== '' && districtMembersData)) && (
        <>
          <SearchMembersForm
            initialValues={searchFields as SearchFormValues}
            loading={loading}
            submitHandler={handleSearch}
            title={{
              classes:
                'desktop:mb-5 tablet:mb-0 tablet:normal-case capitalize mobile:mb-2',
              value: getRoleTabDetails(
                isClubRole.value,
                isDistrictRole.value,
                isRegionalGroupRole.value
              ).searchTitle,
            }}
            isLabelsHidden
            isSearchFieldClassEnabled
          />

          <TableWithTabs
            activeTabInfo={activeTab}
            tabs={tabs}
            onChangeTab={setActiveTab}
          />
        </>
      )}
    </>
  );
};

export default SelectOfficer;
